import React from 'react';
import { ScoreViewer } from "./Components/ScoreViewer";
import './App.css';
import { redraw } from './Components/redrawHandler';

// if (window.location.pathname === "/") {
// 	window.location.href = "https://www.beatmatika.com";
// }

window.addEventListener('resize', () => redraw());

function App() {
	return (
		<ScoreViewer></ScoreViewer>
	);
}

export default App;
