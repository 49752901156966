export function pathParser() {
	let path = window.location.pathname;

	if (path.endsWith("/")) {
		path = path.substring(0, path.length - 1);
	}

	if (path.startsWith("/")) {
		path = path.substring(1);
	}

	console.log(path);

	const pathSplit = path.split("/");

	return pathSplit;
}
