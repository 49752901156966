export enum ORIENTATION {
	PORTRAIT,
	LANDSCAPE,
}

export interface Padding {
    left: number;
}

export interface Bar {
    width: number;
    height: number;
    padding: Padding;
}

export interface Calculated {
    width: number;
    height: number;
}

export interface Viewport {
    width: number;
    height: number;
    aspectRatio: number;
    orientation: ORIENTATION;
}

export interface ViewSettingsContext {
    patternIndex: number;
    data: any;
}

export interface ViewSettingsClass {
    context: ViewSettingsContext,
    bar: Bar;
    calculated: Calculated;
    viewport: Viewport;
}

