import { PatternBeat } from "./PatternBeat";


export class PatternVoice {
	public note: number;

	public pattern: Array<PatternBeat>;

	public beatShortHand = [];

	public vexShortHand = [];

	constructor() {
		this.pattern = [];
		this.vexShortHand = [];
		this.beatShortHand = [];
	}

	public mappedNote(rest: boolean): string {
		if (rest === true) {
			return {
				70:'F/4',
				69: 'D/5',
				68: 'G/5',
			}[this.note];
		}

		return {
			70:'F/4',
			69: 'C/5', // x2 is X snare, x3 is circled X
			68: 'G/5/x2', // x3 is circled hat
			// x2 is closed hat
			// x1 is fat closed hat
			//
		}[this.note];
	}

	public get stemUp(): boolean {
		return {
			70: false,
			69: true,
			68: true,
		}[this.note];
	}
}
