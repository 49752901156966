import { IPatternBeat } from "./IPatternBeat";
import { PatternBeat } from "./PatternBeat";
import { PatternVoice } from "./PatternVoice";

export class VexBeat {
	public timeOffset: Number;

	public duration: string;
}

export class VexQuarter {
	public timeOffset: number;

	public beats: Array<VexBeat>;

	constructor() {
		this.beats = [];
	}
}

export class VexBar {
	public quarters: Array<VexQuarter>;

	constructor() {
		this.quarters = [];
	}
}

export class VexShortHand {
	public bars: Array<VexBar>;

	constructor() {
		this.bars = [];
	}
}

export class PatternVoiceGroup {
	public voices: Array<PatternVoice>;

	public vexShortHand: VexShortHand;

	public beatShortHand = [];

	public stemDirection: number;

	constructor() {
		this.vexShortHand = new VexShortHand();
	}

	public get stemUp(): boolean {
		return true;
	}
}

function setCharAt(str,index,chr) {
    if(index > str.length-1) return str;
    return str.substring(0,index) + chr + str.substring(index+1);
}

const lookupBeats = {
	"r   ": ["4r"],
	"r  x": ["8r.", "16"],
	"r x ": ["8r", "8"],
	"r xx": ["8r", "16", "16"],
	"rx  ": ["16r", "8."],
	"rx x": ["16r", "8", "16"],
	"rxx ": ["16r", "16", "8"],
	"rxxx": ["16r", "16", "16", "16"],
	"x   ": ["4"],
	"x  x": ["8.", "16"],
	"x x ": ["8", "8"],
	"x xx": ["8", "16", "16"],
	"xx  ": ["16", "8."],
	"xx x": ["16", "8", "16"],
	"xxx ": ["16", "16", "8"],
	"xxxx": ["16", "16", "16", "16"],
};

export class PatternDataVexConverter {
	public pattern: Array<PatternBeat>;

	public notes: Set<number>;

	public voices: Array<PatternVoice>;

	public voiceGroups: Array<PatternVoiceGroup>;

	public bars: number;

	constructor(pattern: Array<PatternBeat>, bars: number) {
		this.pattern = this.hydrate(pattern);

		this.notes = new Set<number>();

		this.bars = bars;

		this.load();
	}

	public hydrate(pattern: Array<IPatternBeat>): Array<PatternBeat> {
		const result = pattern.map((beat: IPatternBeat) => new PatternBeat(beat));

		result.forEach((beat: PatternBeat) => beat.pattern = result);

		return result;
	}

	public load() {
		this.findUniqueNotes();

		this.calculateOffsets();

		this.addStartingRests();

		this.separateVoices();

		this.grid();
	}

	public findUniqueNotes(): void {
		this
			.pattern
			.forEach((beat: PatternBeat) => {
				this.notes.add(beat.note);
			});
	}

	public calculateOffsets(): void {
		this
			.pattern
			.forEach((beat: PatternBeat, beatIndex: number) => {
				if (beat.timeOffset === 0) {
					beat.beatOffset = 0;
				} else {
					beat.beatOffset = (beat.timeOffset / 96) / 4;
				}
			});
	}

	public addStartingRests(): void {
		this.notes
			.forEach((note: number) => {
				const findNote = this.pattern.find((beat: PatternBeat) => beat.beatOffset === 0 && beat.note === note);

				if (findNote === undefined) {
					const newRest = new PatternBeat();
					newRest.note = note;
					newRest.beatOffset = 0;
					newRest.timeOffset = 0;
					newRest.velocity = 0;
					newRest.pattern = this.pattern;

					this.pattern.unshift(newRest);
				}
			});
	}

	patternToGrid(BEATS, SUBBEATS, PPQ, voice: PatternVoice) {
		const buildVoice = [];

		for (let barIndex = 0; barIndex < this.bars; barIndex++) {
			let buildBar = [];

			for (let beatIndex = 0; beatIndex < BEATS; beatIndex++) {
				let buildSixteenths = [];

				for (let subbeat = 0; subbeat < SUBBEATS; subbeat++) {
					const ticks = (barIndex * BEATS * PPQ) + (beatIndex * PPQ) + (subbeat * (0.25 * PPQ));

					const findBeat = voice.pattern.find((voice: PatternBeat) => voice.timeOffset === ticks);

					// drop rests to begin
					let emptySpace = subbeat === 0 ? "r" : " ";

					// empty space
					if (findBeat === undefined) {
						buildSixteenths.push(emptySpace);
					} else {
						// rest
						if (findBeat.velocity === 0) {
							buildSixteenths.push(emptySpace);
						} else {
							buildSixteenths.push("x");
						}
					}
				}

				const key = buildSixteenths.join("");

				buildBar.push(key);

				//const beatPattern = lookupBeats[key];
				//beatPattern.forEach((beat) => buildBar.push(beat));
			}

			buildVoice.push(buildBar);
		}

		voice.beatShortHand = buildVoice;
		//voice.vexShortHand.push(buildVoice);
	}

	public grid(): void {
		const BEATS = 4;
		const SUBBEATS = 4;
		const PPQ = 96;

		this.voices.forEach((voice: PatternVoice) => this.patternToGrid(BEATS, SUBBEATS, PPQ, voice));
	}

	public separateVoices(): void {
		this.voices = [];
		this.voiceGroups = [];

		this
			.pattern
			.forEach((beat: PatternBeat) => {
				const voice = this.voice(beat.note);

				voice.pattern.push(beat);

				//this.voices.push(voice);
			});
	}

	public voice(note: number): PatternVoice {
		let voice = this.voices.find((findVoice: PatternVoice) => findVoice.note === note);

		if (voice === undefined) {
			voice = new PatternVoice();
			voice.note = note;

			this.voices.push(voice);
		}

		return voice;
	}


	public groupVoices(voices: number[], stemDirection: number, barNumbers: number): void {
		const BEATS = 4;
		const SUBBEATS = 4;
		const PPQ = 96;

		const foundVoices = this.voices.filter((voice: PatternVoice) => voices.indexOf(voice.note) !== -1);

		const patternVoiceGroup = new PatternVoiceGroup();
		patternVoiceGroup.voices = foundVoices;
		patternVoiceGroup.stemDirection = stemDirection;

		if (foundVoices.length === 0) {
			const emptyPatternVoice = new PatternVoice();
			emptyPatternVoice.beatShortHand = [];

			for (let bar = 0; bar < barNumbers; bar++) {
				emptyPatternVoice.beatShortHand.push([
					"r   ",
					"r   ",
					"r   ",
					"r   ",
				]);
			}

			emptyPatternVoice.note = voices[0];

			foundVoices.push(emptyPatternVoice);
		}

		patternVoiceGroup.beatShortHand = foundVoices[0].beatShortHand;

		// loop through each voice
		foundVoices.forEach((voice: PatternVoice) => {
			// loop through each bar
			voice.beatShortHand.forEach((bar, barIndex) => {
				// loop through each 1/4
				bar.forEach((beat: string, beatIndex: number) => {
					// console.log(patternVoiceGroup.beatShortHand[barIndex][beatIndex], beat);

					// create a pattern overlaying the patterns
					for (let sixteenth = 0; sixteenth < 4; sixteenth++) {
						if (beat.substring(sixteenth, sixteenth + 1) === 'x') {
							patternVoiceGroup.beatShortHand[barIndex][beatIndex] = setCharAt(patternVoiceGroup.beatShortHand[barIndex][beatIndex], sixteenth, 'x');
						}
					}
				});
			});
		});

		let bars = [];

		patternVoiceGroup
			.beatShortHand
			.forEach((bar, barIndex) => {

				let vexBar = new VexBar();

				// loop through each 1/4
				bar
					.forEach((beat: string, beatIndex: number) => {
						let vexQuarter = new VexQuarter();
						let mappedNotes = lookupBeats[beat];

						let beatMapOffset = 0;
						for (let beatSlice = 0; beatSlice < 4; beatSlice++) {
							// if there's a beat
							if (beat.substring(beatSlice, beatSlice + 1) === 'x') {
								let vexNote = new VexBeat();
								vexNote.duration = mappedNotes[beatMapOffset];
								vexNote.timeOffset = (barIndex * BEATS * PPQ) + (beatIndex * PPQ) + (beatSlice * (0.25 * PPQ));

								vexQuarter.beats.push(vexNote)

								beatMapOffset++;
							}

							// rest
							if (beat.substring(beatSlice, beatSlice + 1) === 'r') {
								let vexNote = new VexBeat();
								vexNote.duration = mappedNotes[beatMapOffset];
								vexNote.timeOffset = (barIndex * BEATS * PPQ) + (beatIndex * PPQ) + (beatSlice * (0.25 * PPQ));

								vexQuarter.beats.push(vexNote)

								beatMapOffset++;
							}
						}

						// needs a rest
						if (vexQuarter.beats.length === 0) {
							if (mappedNotes[0] !== undefined) {
								let vexNote = new VexBeat();

								vexNote.duration = mappedNotes[beatMapOffset];
								vexNote.timeOffset = (barIndex * BEATS * PPQ) + (beatIndex * (PPQ));

								vexQuarter.beats.push(vexNote)
							}
						}

						vexBar.quarters.push(vexQuarter);
					});

				patternVoiceGroup.vexShortHand.bars.push(vexBar)
			});

		console.log(patternVoiceGroup.beatShortHand);
		console.log(patternVoiceGroup.vexShortHand);

		this.voiceGroups.push(patternVoiceGroup);
	}
}
