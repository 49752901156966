import Vex from "vexflow";

import { Album } from "../models/Album";
import { PatternBeat } from "../models/PatternBeat";
import { PatternDataVexConverter, PatternVoiceGroup, VexBeat, VexQuarter } from "../models/PatternDataVexConverter";
import { PatternVoice } from "../models/PatternVoice";
import { updateViewport } from "./updateViewport";
import { ORIENTATION } from "./ViewSettingsClass";
import { viewSettings } from "./viewSettings";


const drumTypeNoteMap = {
	'KICK': 'F/3',

	'SNARE': 'B/4',

	'HI_HAT': 'F/5',
};

export class Score {
	public fps: string;
	public enableMetronome: boolean;
	public patternIndex: number;
	public metronomeOnFor: number;
	public metronomeOffFor: number;
	public currentTime: number;
	public currentBeatNumber: number;

	public soundOffsetTime: number;
	public patternData: Array<Array<PatternBeat>>;

	public bpm: number;
	public startTime: number;
	public bars: number;

	public createTrainingReps: number;

	public data: any;
	public album: Album;

	public converter: PatternDataVexConverter;

	constructor() {
		window.onerror = (msg, url, lineNo, columnNo, error) => {
			this.error(msg, url, lineNo, columnNo, error);
		};

		window.addEventListener('error', (event) => {
			const div = document.getElementById("error");
			// div.innerText = event.toString();
		});

		console.log("Viewsettings", viewSettings.viewport);
	}

	public status(text: string): void {
		const div = document.getElementById("status");

		// div.innerText = text;
	}

	public error(msg: string | Event, url: string | undefined, lineNo: number | undefined, columnNo: number | undefined, error: Error | undefined): void {
		const div = document.getElementById("error");
		// div.innerText = msg;
	}

	public initialise() {
		this.status(`Start ${ new Date().getTime()}`);

		const data = this.load();

		if (data === null) {
			return;
		}

		const converter = new PatternDataVexConverter(this.patternData[viewSettings.context.patternIndex], this.bars);

		converter.groupVoices([70], Vex.Flow.Stem.DOWN, this.bars);
		converter.groupVoices([68, 69], Vex.Flow.Stem.UP, this.bars);

		this.converter = converter;

		updateViewport(viewSettings, this);
	}

	public render(element: string): void {
		const div = document.getElementById(element) as HTMLElement;
		const harness = document.getElementById("harness") as HTMLElement;

		div.innerHTML = "";

		const renderer = new Vex.Flow.Renderer(div, Vex.Flow.Renderer.Backends.SVG);

		const BEATS = 4;
		const SUBBEATS = 4;
		const PPQ = 96;

		console.log("Render");

		// Configure the rendering context.
		renderer.resize(harness.offsetWidth, harness.offsetHeight);

		viewSettings.calculated.width = viewSettings.bar.width;
		viewSettings.calculated.height = (this.bars + 1) * viewSettings.bar.height;

		if (viewSettings.viewport.orientation === ORIENTATION.LANDSCAPE) {
			viewSettings.calculated.width = viewSettings.bar.width * 2;
			viewSettings.calculated.height = viewSettings.bar.height * Math.floor((this.bars + 1) / 2);
		}

		const context = renderer.getContext();

		context
			.scale(1, 1)
			.setFont("Arial", 10, 500)
			.setBackgroundFillStyle("#eed");

		const formatter = new Vex.Flow.Formatter();

		for (let barIndex = 0; barIndex < this.bars; barIndex++) {
			let staveX = 0;
			let staveY = viewSettings.bar.height * barIndex;
			let staveWidth = viewSettings.bar.width;

			if (viewSettings.viewport.orientation === ORIENTATION.LANDSCAPE) {
				staveX = (barIndex % 2) * viewSettings.bar.width;
				staveY = (viewSettings.bar.height * Math.floor(barIndex / 2));
			}

			const stave = new Vex.Flow.Stave(staveX, staveY, staveWidth);

			if (barIndex === 0) {
				stave.setBegBarType(Vex.Flow.Barline.type.REPEAT_BEGIN);

				// add BPM
				// const tempo = new Vex.Flow.StaveTempo({ name: `${this.bpm} BPM`, bpm: this.bpm, dots: 0, duration: "" }, staveX, -40);
				// stave.addModifier(tempo);
				stave.addTimeSignature("4/4");

				stave.addClef("percussion");
			}

			if (barIndex === this.bars - 1) {
				stave.setEndBarType(Vex.Flow.Barline.type.REPEAT_END);
			}

			// if (barIndex === 0) {
			// 	const tempoOptions = {
			// 		name: `${this.bpm} BPM`,
			// 		bpm: this.bpm,
			// 		dots: 0,
			// 		duration: "",
			// 	};

			// 	const staveTempo = new Vex.Flow.StaveTempo(tempoOptions, 10, -60)
			// 	staveTempo["font"].weight = 'italic';

			// 	stave.addModifier(staveTempo);
			// }

			console.log("bar", barIndex);

			stave
				.setContext(context)
				.draw();

			let notes: any[][] = [];

			this
				.converter
				.voiceGroups
				.forEach((voiceGroup: PatternVoiceGroup) => {
					console.log('New voicegroup');
					voiceGroup.voices.forEach((value: PatternVoice) => console.log(value.note));

					const voiceNotes: Vex.Flow.StaveNote[] = [];
					const vexBar = voiceGroup.vexShortHand.bars[barIndex];
					const beatBar = voiceGroup.beatShortHand[barIndex];

					vexBar.quarters.forEach((vexQuarter: VexQuarter, vexIndex) => {
						let beatOffset = 0;

						console.log(vexQuarter, beatBar);

						vexQuarter.beats.forEach((vexBeat: VexBeat , quarterIndex: number) => {
							let vexItem = vexBeat.duration;

							const rest = vexItem.indexOf("r") !== -1;
							const dotted = vexItem.indexOf(".") !== -1;
							const articulation = vexItem.indexOf("a>") !== -1;

							vexItem = vexItem.replace("r", "");
							vexItem = vexItem.replace(".", "");

							const duration = parseInt(vexItem, 10);

							const keys = [];

							// create keys from voices with a beat.
							// voiceGroup.beatShortHand[barIndex][beatIndex][sixteenthIndex]

							// voice.mappedNote(rest)

							voiceGroup
								.voices
								.forEach((voice: PatternVoice) => {
									// does this voice have a note at the beat offset?
									const ticks = vexBeat.timeOffset;

									console.log('ticks: ', ticks);

									const findBeat = voice.pattern.find((beat: PatternBeat) => beat.timeOffset === ticks);

									if (findBeat !== undefined) {
										if (findBeat.velocity !== 0) {
											keys.push(voice.mappedNote(rest));
										}
									}
								});

							if (keys.length === 0) {
								console.log('No keys found');
								keys.push(voiceGroup.voices[0].mappedNote(true));
							}

							let durationModified = (1 / duration);

							if (dotted) {
								durationModified = durationModified * 1.5;
							}

							beatOffset += durationModified;

							const options = {
								keys,

								duration: `${duration}${dotted ? 'd' : ''}${rest ? 'r' : ''}`,

								stem_direction: voiceGroup.stemDirection,
							}

							console.log(options);

							const newNote = new Vex.Flow.StaveNote(options);
							// const newNote = new VF.GhostNote({
							// 	duration: options.duration,
							// });

							if (dotted) {
								newNote.addDotToAll();
							}

							if (articulation) {
								newNote.addArticulation(0, new Vex.Flow.Articulation('a>'));
							}

							// sticking
							// .addModifier(0, vf.Annotation({ text: 'L' })),

							// https://github.com/0xfe/vexflow/blob/master/tests/percussion_tests.js

							voiceNotes.push(newNote);
						});
					});

					notes.push(voiceNotes);
				});

			var voices = notes.map(() => new Vex.Flow.Voice({num_beats:4, beat_value: 4, resolution: Vex.Flow.RESOLUTION}));

			voices.forEach((voice) => voice.setStrict(false));

			notes = notes.map((notesList: Array<Vex.Flow.StaveNote>, noteIndex: number) => {
				if (notesList.length === 4) {
					if (notesList.filter((checkNote: Vex.Flow.StaveNote) => checkNote.getDuration() === "4" && checkNote.getNoteType() === "r").length === 4) {
						return [
							new Vex.Flow.StaveNote({
								keys: [
									this.converter.voiceGroups[noteIndex].voices[0].mappedNote(true),
								],

								duration: "1r",

								stem_direction: this.converter.voiceGroups[noteIndex].stemDirection,
							}).setCenterAlignment(true)
						]
					}
				}

				return notesList;
			});

			voices.forEach((voice, index) => voice.addTickables(notes[index]));

			// const beams = voices.map((voice) => VF.Beam.applyAndGetBeams(voice, VF.Stem.UP))

			const beams: Vex.Flow.Beam[][] = voices.map((voice) => Vex.Flow.Beam.generateBeams(voice.getTickables() as Vex.Flow.StemmableNote[], {
				maintain_stem_directions: true,
				flat_beams: true,
			} as any)); // vexflow types are outdated

			beams.forEach((beamVoice: Vex.Flow.Beam[]) => {
				// beamVoice.forEach((beam: Vex.Flow.Beam) => beam["render_options"].slope_cost = 0);
			});

			const beatVoice = new Vex.Flow.Voice({num_beats:4, beat_value: 4, resolution: Vex.Flow.RESOLUTION});

			beatVoice.setStrict(false);

			const beatNotes: Array<Vex.Flow.TextNote> = [];

			for (let sixteenth = 0; sixteenth < 4; sixteenth++) {
				// beatNotes.push(
				// 	new Vex.Flow.TextNote({
				// 		duration: "16",
				// 		text: `${sixteenth + 1}`,
				// 		smooth: true,
				// 		font: {
				// 			family: "Gonville",
				// 			size: 10,
				// 			weight: "500",
				// 		}
				// 	}).setContext(context) as Vex.Flow.TextNote
				// );
				// beatNotes.push(
				// 	new Vex.Flow.TextNote({
				// 		duration: "16",
				// 		text: "e",
				// 		smooth: true,
				// 		font: {
				// 			family: "Gonville",
				// 			size: 10,
				// 			weight: "500",
				// 		}
				// 	}).setContext(context) as Vex.Flow.TextNote
				// );
				// beatNotes.push(
				// 	new Vex.Flow.TextNote({
				// 		duration: "16",
				// 		text: "+",
				// 		smooth: true,
				// 		font: {
				// 			family: "Gonville",
				// 			size: 10,
				// 			weight: "500",
				// 		}
				// 	}).setContext(context) as Vex.Flow.TextNote
				// );
				// beatNotes.push(
				// 	new Vex.Flow.TextNote({
				// 		duration: "16",
				// 		text: "a",
				// 		smooth: true,
				// 		font: {
				// 			family: "Gonville",
				// 			size: 10,
				// 			weight: "500",
				// 		}
				// 	}).setContext(context) as Vex.Flow.TextNote
				// );
				beatNotes.push(
					new Vex.Flow.TextNote({
						duration: "8",
						text: `${sixteenth + 1}`,
						smooth: true,
						font: {
							family: "Lato",
							size: 10,
							weight: "500",
						}
					}).setContext(context) as Vex.Flow.TextNote
				);
				beatNotes.push(
					new Vex.Flow.TextNote({
						duration: "8",
						text: "+",
						smooth: true,
						font: {
							family: "Lato",
							size: 10,
							weight: "500",
						}
					}).setContext(context) as Vex.Flow.TextNote
				);
			}

			beatNotes.forEach((beatNote: Vex.Flow.TextNote) => {
				beatNote.setLine(-2);
				beatNote.setJustification(Vex.Flow.TextNote.Justification.CENTER);
			});

			beatVoice.addTickables(beatNotes);

			voices.push(beatVoice);

			voices.forEach((voice) => voice.setStave(stave));

			formatter
				.joinVoices(voices)
				.formatToStave(voices, stave, { align_rests: false, context: context })
				.format(voices, stave.getWidth() - (barIndex === 0 ? 120 : 50));

			voices.forEach((voice) => voice.draw(context, stave));

			voices.forEach((voice: Vex.Flow.Voice) => {
				voice.getTickables().forEach((tickable: Vex.Flow.Tickable) => {
					console.log(tickable.getBoundingBox()?.getX());
				});
			});

			beams.forEach((beam) => beam.forEach((b) => b.setContext(context).draw()));

		}

		// document.querySelector(".header-name").innerHTML = this.album.name;
		// document.querySelector(".header-artist").innerHTML = this.album.author.name
	}

	public load() {
		const rhythm = viewSettings.context.data;

		if (rhythm === null) {
			return null;
		}

		this.data = rhythm;
		this.album = new Album(rhythm.album);
		this.patternData = rhythm.patternData;
		this.bpm = this.data.bpm;
		this.bars = rhythm.bars;

		return rhythm;
	}
}
