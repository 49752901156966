import { ORIENTATION, ViewSettingsClass } from "./ViewSettingsClass";


export const viewSettings: ViewSettingsClass = {
	context: {
		patternIndex: 0,
		data: null,
	},

	bar: {
		width: 400,
		height: 160,

		padding: {
			left: 10,
		}
	},

	calculated: {
		width: 0,
		height: 0,
	},

	viewport: {
		width: 0,
		height: 0,
		aspectRatio: 1,

		orientation: ORIENTATION.PORTRAIT,
	},
};
