import { IPatternBeat } from "./IPatternBeat";

export class PatternBeat implements IPatternBeat {
    public timeOffset: number;
    public note: number;
    public name: string;
    public velocity: number;
	public toDelete: boolean;

	public beatOffset: number;

	public pattern: Array<PatternBeat>;

    constructor(data?: any) {
		this.toDelete = false;

		if (data !== undefined) {
			Object.keys(data).forEach((key) => this[key] = data[key]);
		}
	}

	public get distanceToNextBeat(): number {
		if (this.nextBeat === undefined) {
			return 0;
		}

		return (this.nextBeat.beatOffset - this.beatOffset);
	}

	public get duration(): number {
		let distance = this.distanceToNextBeat;

		let result = 1 / distance;

		if (this.distanceToNextBeat === 0) {
			return 1 / (1 - this.beatOffset);
		}

		return result;
	}

	public get nextBeat(): PatternBeat {
		return this.pattern.find((value: PatternBeat, findIndex: number) => value.timeOffset > this.timeOffset && value.note === this.note);
	}
}
