import { Author } from "./Author";
import { Pattern } from "./Pattern";
import { Mapping } from "./Mapping";
import { DrumTypes } from "./DrumTypes";
import { RhythmFolderStorageModel } from "../rhythms/RhythmFolderStorageModel";

export class Album {
    public id: number;
    public name: string;
    public author: Author;
    public art: string;
    public art_credit: string;
    public pattern_set: Pattern[];
    public mappings: Mapping[];
    public drum_kit: number;
    public folder: RhythmFolderStorageModel;

    public downloaded: boolean;
    public downloading: boolean;
    public isLoading: boolean;

    public noteCache: any;
    public albumType: string;

    public dateTimeCreated: string;

    constructor(data?: any) {
        this.noteCache = {};
        this.isLoading = false;

        if (data !== undefined) {
            Object.keys(data).forEach((key) => {
                this[key] = data[key]
            });
        }
    }

    public getNoteNumber(for_type: DrumTypes): number {
        if ((for_type !== undefined) &&
            (typeof this.noteCache[for_type.toString()] !== "undefined")) {
            //console.log(`${for_type} cached as ${this.noteCache[for_type.toString()]}`);
            return this.noteCache[for_type.toString()];
        } else {
            let filterItems = this.mappings.filter(mapping => mapping.drum_type.enum === for_type);

            if (filterItems.length > 0) {
                this.noteCache[for_type.toString()] = filterItems[0].note;

                //console.log(`${for_type} calculated as ${filterItems[0].note}`);

                return filterItems[0].note;
            }
        }

        return 0;
    }

    public get mediumArt(): string {
        if (this.art.startsWith("http")) {
            let filename = this.art.split("/");
            let lastPart = filename.pop();

            filename.push(`med_${lastPart}`);

            return filename.join("/");
        } else {
            return this.art;
        }
    }
}
