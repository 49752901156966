import React, { ReactNode } from 'react';
import { loadData } from './loadData';
import { redraw, redrawHandler } from './redrawHandler';
import { viewSettings } from "./viewSettings";

export const API_URL = "https://admin.beatmatika.com";

export class ScoreViewer extends React.Component {
	state: any;

	constructor(props: {} | Readonly<{}>) {
		super(props);

		this.state = {
			viewSettings,
		} as any;
	}

	public componentDidMount() {
		this.loadData();
	}

	public loadData(): void {
		loadData()
			.then((data) => {
				console.log(data);
				
				const newState = {
					...this.state,
				}

				newState.viewSettings.context.patternIndex = 0;
				newState.viewSettings.context.data = data;

				this.setState(newState);
			}, (error) => {
				console.log(error);
			});
	}

	public componentDidUpdate() {
		redrawHandler();
	}

	public back(): void {
		const newState = {
			...this.state,
		}

		newState.viewSettings.context.patternIndex--;

		this.setState(newState);
	}

	public next(): void {
		const newState = {
			...this.state,
		}

		newState.viewSettings.context.patternIndex++;

		this.setState(newState);
	}

	public viewCanShowBack(): boolean {
		return this.state.viewSettings.context.patternIndex > 0;
	}

	public viewCanShowNext(): boolean {
		return this.state.viewSettings.context.patternIndex < this.state.viewSettings.context.data.patternData.length - 1;
	}

	public getNotes(): string[] {
		let result = "";

		try {
			result = this.state.viewSettings.context.data.patternNotes[this.state.viewSettings.context.patternIndex]
		} catch (e) {

		}
		
		if (result === null) {
			result = "";
		}		

		return result.split("\n");
	}

	public render(): ReactNode {
		if (this.state.viewSettings.context.data === null) {
			return (
				<div id="circle1"></div>
			);
		}

		return (
			<React.Fragment>
				<div className="flex-container">
					<div className="flex-item">
						<div className="header">
							<div className="header-name">{ this.state.viewSettings.context.data.album.name }</div>
							{/* <div className="header-artist">{ this.state.viewSettings.context.data.album.author.name }</div> */}
						</div>
						<div className="pages">
							{ this.state.viewSettings.context.patternIndex + 1 } of { this.state.viewSettings.context.data.patternData.length }
						</div>
						<div className="controls">
							<button disabled={ !this.viewCanShowBack() } className="button left" onClick={ () => this.back() }>Back</button>

							<button disabled={ !this.viewCanShowNext() } className="button right" onClick={ () => this.next() }>Next</button>
						</div>
						<div className="notes">
							{ this.getNotes().map((noteItem) => (
								<div>{ noteItem}</div>
							)) }
						</div>
					</div>
					<div className="flex-item">
						<div id="harness">
							<div id="score"></div>
						</div>
					</div>
				</div>
			</React.Fragment>
		)
	}
}

