import { Score } from './Score';
import { viewSettings } from "./viewSettings";
import { ORIENTATION } from './ViewSettingsClass';


export async function redrawHandler() {
	var score = new Score();

	await score.initialise();

	score.render("score");

	const svg = document.querySelector("svg") as SVGSVGElement;

	const topOffset = `${viewSettings.viewport.orientation === ORIENTATION.PORTRAIT ? 0 : 50}`;

	const viewBoxAttributes = `0 ${topOffset} ${viewSettings.calculated.width} ${viewSettings.calculated.height - 150}`;

	svg.setAttribute("viewBox", viewBoxAttributes);
}

let redrawHandle: number;
export function redraw(): void {
	window.clearTimeout(redrawHandle);

	redrawHandle = window.setTimeout(() => {
		redrawHandler();
	}, 500);
}
