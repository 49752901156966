import { pathParser } from './pathParser';
import { viewSettings } from "./viewSettings";
import { API_URL } from './ScoreViewer';

export function loadData() {
	return new Promise((resolve, reject) => {
		const pathData = pathParser();

		let init: RequestInit = {
			headers: {
				"beatmatika": "ios",
			} as HeadersInit
		};

		const url = `${API_URL}/publishedrhythm/${pathData[0]}/`;

		if (pathData[1]) {
			viewSettings.context.patternIndex = parseInt(pathData[1]);
		}

		fetch(url, init)
			.then((value: Response) => value.json())
			.then((response) => {
				let parsed = null;

				try {
					parsed = JSON.parse(response.data);
				} catch (e) {
					parsed = response.data;
				}

				resolve(parsed);
			}, (error) => {
				reject();
			});
	});
}
