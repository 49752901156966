import { Score } from './Score';
import { ORIENTATION, ViewSettingsClass } from './ViewSettingsClass';


export function updateViewport(viewSettings: ViewSettingsClass, score: Score): void {
	viewSettings.viewport.width = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
	viewSettings.viewport.height = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);

	viewSettings.viewport.aspectRatio = viewSettings.viewport.width / viewSettings.viewport.height;

	if (viewSettings.viewport.aspectRatio > 1.2) {
		viewSettings.viewport.orientation = ORIENTATION.LANDSCAPE;
	} else {
		viewSettings.viewport.orientation = ORIENTATION.PORTRAIT;
	}

	if (score.bars === 1) {
		viewSettings.viewport.orientation = ORIENTATION.PORTRAIT;
	}
}
